<template>
  <div class="footer-margin">
    <section>
      <div class="container-fluid plr-80">
        <div class="property-name-wrapper row">
          <div class="col-md-6 hotel-rating">
            <p>Hotel booking</p>
            <h4 class="d-flex align-items-center">
              <span> Hotel Berlin</span
              ><span
                ><i class="fa fa-star" aria-hidden="true"></i
                ><i class="fa fa-star" aria-hidden="true"></i
              ></span>
            </h4>
            <div class="access d-flex">
              <li><a href="#">Mitte, Berlin</a></li>
              <li><a href="#">Show on map</a></li>
              <li>2.6 km from centre</li>
              <li>Metro access</li>
            </div>
          </div>
          <div class="col-md-6 share-outer">
            <a href="#"> <img src="../assets/images/share.png" />Share </a>
            <a href="#"> <img src="../assets/images/symbols.png" />Save </a>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container-fluid plr-80">
        <div class="property-pics-outer row position-relative">
          <a href="#" class="show-all">Show all photos</a>
          <div class="col-6 single-image">
            <div class="pics-img-outer">
              <img src="../assets/images/Frame 105.png" alt="" />
            </div>
          </div>
          <div class="col-6">
            <div class="row images-4">
              <div class="col-6">
                <div class="pics-img-outer">
                  <img src="../assets/images/Frame 106.png" alt="" />
                </div>
              </div>
              <div class="col-6">
                <div class="pics-img-outer">
                  <img src="../assets/images/Frame 107.png" alt="" />
                </div>
              </div>
              <div class="col-6">
                <div class="pics-img-outer">
                  <img src="../assets/images/Frame 108.png" alt="" />
                </div>
              </div>
              <div class="col-6">
                <div class="pics-img-outer">
                  <img src="../assets/images/Frame 109.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bookProperty" v-if="showBookNow">
        <div class="container-fluid plr-80">
          <div class="row">
            <div class="col-md-6"></div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-6 col-lg-6">
                  <h5>Garden view balconi suites</h5>
                  <p>21 Oct 2021 - 23 Oct 2021<span>2 nights</span></p>
                </div>

                <div class="col-md-6 col-lg-6 bookProperty-inner">
                  <div>
                    <h5>EUR 250.00</h5>
                    <p>Total price<span>(VAT included)</span></p>
                  </div>
                  <b-button class="bgfff-fill"
                    ><router-link to="/payment_page"
                      >Book now</router-link
                    ></b-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container-fluid plr-80">
        <div class="property-pics-mob">
          <b-carousel
            id="carousel-fade"
            style="text-shadow: 0px 0px 2px #000"
            fade
            indicators
            img-width="1024"
            img-height="480"
          >
            <b-carousel-slide
              img-src="../assets/images/Frame 105.png"
            ></b-carousel-slide>
            <b-carousel-slide
              img-src="../assets/images/Frame 106.png"
            ></b-carousel-slide>
            <b-carousel-slide
              img-src="../assets/images/Frame 107.png"
            ></b-carousel-slide>
          </b-carousel>
        </div>
      </div>
    </section>

    <section>
      <div class="container-fluid plr-80">
        <div class="property-desc">
          <div class="row align-items-center">
            <div class="col-md-6 col-lg-6">
              <h2>Stay in the heart of Berlin</h2>
              <p>
                This family-run apartment hotel in Berlin’s Charlottenburg
                district is a 2-minute walk from the Kurfürstendamm shopping
                mile. It offers free Wi-Fi internet access.<br /><br />

                All rooms of the Aparthotel VEGA have cable TV, a refrigerator
                and a private bathroom. Apartment accommodation additionally
                includes a fully equipped kitchen. Free mineral water is
                provided on arrival.<br /><br />

                The Aparthotel VEGA is directly opposite a bakery. Breakfast can
                be enjoyed here. There are also several cafés, supermarkets and
                places to have breakfast.within walking distance.<br /><br />

                The Aparthotel VEGA is near the Kaiser Wilhelm Memorial Church,
                Berlin Zoo and the KaDeWe department store. Berlin’s ICC
                exhibition centre is 2 km away.<br /><br />

                The Aparthotel is a 5-minute walk from Uhlandstraße underground
                station, and a 10-minute walk from the S-Bahn (city rail)
                stations of Zoologischer Garten and Savignyplatz. There is a
                direct bus to Tegel Airport.<br /><br />

                Couples particularly like the location — they rated it 8.8 for a
                two-person trip.<br /><br />

                We speak your language!
              </p>
            </div>
            <div class="col-md-6 col-lg-6">
              <div class="row block-4">
                <div class="col-6">
                  <div class="icon-outer">
                    <div class="icon">
                      <img src="../assets/images/wifi.png" alt="" />
                    </div>
                    <h5>Free Wifi</h5>
                    <p>You can cancel booking before Sep 22</p>
                  </div>
                </div>
                <div class="col-6">
                  <div class="icon-outer">
                    <div class="icon">
                      <img src="../assets/images/wifi.png" alt="" />
                    </div>
                    <h5>Free Wifi</h5>
                    <p>You can cancel booking before Sep 22</p>
                  </div>
                </div>
                <div class="col-6">
                  <div class="icon-outer">
                    <div class="icon">
                      <img src="../assets/images/wifi.png" alt="" />
                    </div>
                    <h5>Free Wifi</h5>
                    <p>You can cancel booking before Sep 22</p>
                  </div>
                </div>
                <div class="col-6">
                  <div class="icon-outer">
                    <div class="icon">
                      <img src="../assets/images/wifi.png" alt="" />
                    </div>
                    <h5>Free Wifi</h5>
                    <p>You can cancel booking before Sep 22</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container-fluid plr-80">
        <div class="row">
          <div class="col-12">
            <div class="alert-outer">
              <b-alert show class="tip-color">
                <img src="../assets/images/tickIcon.png" alt="" />
                <div class="alert-txt">
                  <h5>Tip: Stay flexible</h5>
                  <p>
                    Since your dates are a while away, choose free cancellation
                    to stay flexible. A change of plans is no problem when you
                    have free cancellation
                  </p>
                </div>
              </b-alert>
              <b-alert show class="warning-color">
                <img src="../assets/images/warningIcon.png" alt="" />
                <div class="alert-txt">
                  <h5>Warning</h5>
                  <p>
                    From 6 April 2021, your chosen cancellation policy will
                    apply, regardless of Coronavirus. We recommend booking a
                    free cancellation option in case your travel plans need to
                    changen
                  </p>
                  <a href="#">Read more</a>
                </div>
              </b-alert>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="book-stay-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-md-7 m-auto fullView-cal custom-calender">
            <h3 class="text-center heading">Book your stay</h3>
            <div>
              <b-row class="mb-20 calander-label">
                <b-col md="6">
                  <label for="">Check-in-date</label>
                  <b-form-select class="input"> </b-form-select>
                </b-col>
                <b-col md="6">
                  <label for="">Check-out-date</label>
                  <b-form-select class="input"> </b-form-select>
                </b-col>
              </b-row>
            </div>
            <HotelDatePicker v-bind:alwaysVisible="true" format="DD/MM/YYYY">
            </HotelDatePicker>
            <a href="#" class="book mt-2">Check availability</a>
          </div>
        </div>
        <div class="room-details bg-fff row">
          <div class="col-md-8 col-lg-9 text-left right-border p-0">
            <h6>Rooms details</h6>
          </div>
          <div class="col-md-2 col-lg-1 text-center right-border">
            <h6>Quantity</h6>
          </div>
          <div class="col-md-2 col-lg-2 text-center p-0">
            <h6>Booking details</h6>
          </div>
        </div>
        <div class="room-desc bg-fff row">
          <div class="room-desc-inner">
            <div
              class="
                col-md-8 col-lg-9 col-12
                text-left
                right-border
                border-bottom
              "
            >
              <div class="row">
                <div class="col-md-5 p-0 col-lg-5 ptb-24">
                  <b-carousel
                    id="carousel-fade"
                    style="text-shadow: 0px 0px 2px #000"
                    fade
                    controls
                  >
                    <b-carousel-slide
                      img-src="../assets/images/Frame 105.png"
                    ></b-carousel-slide>
                    <b-carousel-slide
                      img-src="../assets/images/Frame 106.png"
                    ></b-carousel-slide>
                  </b-carousel>
                </div>
                <div class="col-md-7 col-lg-7 room-features ptb-24">
                  <div class="title">
                    <h2>Garden view balconi suites</h2>
                    <span>€360.00 per room </span>
                  </div>
                  <p>
                    Overlooking our beautiful garden, our Garden suites offer a
                    large balcony and floor to ceiling glass windows.
                  </p>

                  <ul>
                    <li>
                      <span
                        ><img src="../assets/images/car-icon.svg" alt=""
                      /></span>
                      <h5>2-3 beds</h5>
                    </li>
                    <li>
                      <span
                        ><img src="../assets/images/user-icon.svg" alt=""
                      /></span>
                      <h5>3-4 people</h5>
                    </li>
                    <li>
                      <span
                        ><img src="../assets/images/bath.svg" alt=""
                      /></span>
                      <h5>1 full bath</h5>
                    </li>
                    <li>
                      <span
                        ><img src="../assets/images/balcony.svg" alt=""
                      /></span>
                      <h5>1 balcony</h5>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              class="
                col-md-2 col-lg-1 col-2
                text-center
                right-border
                ptb-24
                border-bottom
                mobView
              "
            >
              <b-form-select v-model="selected" :options="options">
              </b-form-select>
            </div>
            <div class="col-md-2 col-lg-2 col-10 text-center ptb-24 mobView">
              <a href="#" class="book">Book</a>
            </div>
          </div>
          <div class="room-desc-inner">
            <div class="col-md-8 col-lg-9 col-12 text-left right-border">
              <div class="row">
                <div class="col-md-5 p-0 col-lg-5 ptb-24">
                  <b-carousel
                    id="carousel-fade"
                    style="text-shadow: 0px 0px 2px #000"
                    fade
                    controls
                  >
                    <b-carousel-slide
                      img-src="../assets/images/Frame 105.png"
                    ></b-carousel-slide>
                    <b-carousel-slide
                      img-src="../assets/images/Frame 106.png"
                    ></b-carousel-slide>
                  </b-carousel>
                </div>
                <div class="col-md-7 col-lg-7 room-features ptb-24">
                  <div class="title">
                    <h2>Garden view balconi suites</h2>
                    <span class="red-txt">UNAVAILABLE</span>
                  </div>
                  <p>
                    Overlooking our beautiful garden, our Garden suites offer a
                    large balcony and floor to ceiling glass windows.
                  </p>

                  <ul>
                    <li>
                      <span
                        ><img src="../assets/images/car-icon.svg" alt=""
                      /></span>
                      <h5>2-3 beds</h5>
                    </li>
                    <li>
                      <span
                        ><img src="../assets/images/user-icon.svg" alt=""
                      /></span>
                      <h5>3-4 people</h5>
                    </li>
                    <li>
                      <span
                        ><img src="../assets/images/bath.svg" alt=""
                      /></span>
                      <h5>1 full bath</h5>
                    </li>
                    <li>
                      <span
                        ><img src="../assets/images/balcony.svg" alt=""
                      /></span>
                      <h5>1 balcony</h5>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              class="
                col-md-2 col-lg-1 col-2
                mobView
                text-center
                right-border
                ptb-24
                mobView
              "
            >
              <b-form-select v-model="selected" :options="options">
              </b-form-select>
            </div>
            <div
              class="
                col-md-2 col-10 col-lg-2
                mobView
                text-center
                ptb-24
                mobView
              "
            ></div>
          </div>
        </div>

        <div class="room-desc whole-house bg-fff row">
          <div class="room-desc-inner">
            <div class="col-md-9 col-lg-9 col-12 text-left right-border">
              <div class="row">
                <div class="col-md-5 p-0 col-lg-5 ptb-24">
                  <b-carousel
                    id="carousel-fade"
                    style="text-shadow: 0px 0px 2px #000"
                    fade
                    controls
                  >
                    <b-carousel-slide
                      img-src="../assets/images/Frame 105.png"
                    ></b-carousel-slide>
                    <b-carousel-slide
                      img-src="../assets/images/Frame 106.png"
                    ></b-carousel-slide>
                  </b-carousel>
                </div>
                <div class="col-md-7 col-lg-7 room-features ptb-24">
                  <div class="title">
                    <h2>Whole house</h2>
                    <span>€3600.00</span>
                  </div>
                  <p>
                    Overlooking our beautiful garden, our Garden suites offer a
                    large balcony and floor to ceiling glass windows.
                  </p>

                  <ul>
                    <li>
                      <span
                        ><img src="../assets/images/car-icon.svg" alt=""
                      /></span>
                      <h5>2-3 beds</h5>
                    </li>
                    <li>
                      <span
                        ><img src="../assets/images/user-icon.svg" alt=""
                      /></span>
                      <h5>3-4 people</h5>
                    </li>
                    <li>
                      <span
                        ><img src="../assets/images/bath.svg" alt=""
                      /></span>
                      <h5>1 full bath</h5>
                    </li>
                    <li>
                      <span
                        ><img src="../assets/images/balcony.svg" alt=""
                      /></span>
                      <h5>1 balcony</h5>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-lg-3 text-center ptb-24">
              <a href="#" class="book">Book</a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="facilities">
      <div class="container-fluid plr-80">
        <div class="row">
          <div class="col-12">
            <h2 class="heading border-bottom">Facilities and extras</h2>
          </div>
        </div>

        <div class="row facility-listing">
          <div class="col-md-3 col-lg-3">
            <h5>
              <span><img src="../assets/images/bath.svg" alt="" /></span>
              Bathroom
            </h5>

            <ul>
              <li>
                <p>Lock on bedroom door</p>
              </li>
              <li>
                <p>Hangers</p>
              </li>
              <li>
                <p>Hair dryer</p>
              </li>
              <li>
                <p>Iron</p>
              </li>
              <li>
                <p>Washer</p>
              </li>
              <li>
                <p>Drye</p>
              </li>
            </ul>
            <h5>
              <span><img src="../assets/images/plug.svg" alt="" /></span> Media
              & tehcnology
            </h5>

            <ul>
              <li>
                <p>Lock on bedroom door</p>
              </li>
              <li>
                <p>Hangers</p>
              </li>
              <li>
                <p>Hair dryer</p>
              </li>
              <li>
                <p>Iron</p>
              </li>
              <li>
                <p>Washer</p>
              </li>
              <li>
                <p>Drye</p>
              </li>
            </ul>
          </div>
          <div class="col-md-3 col-lg-3">
            <h5>
              <span><img src="../assets/images/bath.svg" alt="" /></span>
              Kitchen
            </h5>

            <ul>
              <li>
                <p>Lock on bedroom door</p>
              </li>
              <li>
                <p>Hangers</p>
              </li>
              <li>
                <p>Hair dryer</p>
              </li>
              <li>
                <p>Iron</p>
              </li>
              <li>
                <p>Washer</p>
              </li>
              <li>
                <p>Drye</p>
              </li>
              <li>
                <p>Lock on bedroom door</p>
              </li>
              <li>
                <p>Hangers</p>
              </li>
              <li>
                <p>Hair dryer</p>
              </li>
              <li>
                <p>Iron</p>
              </li>
              <li>
                <p>Washer</p>
              </li>
              <li>
                <p>Drye</p>
              </li>
            </ul>
          </div>
          <div class="col-md-3 col-lg-3">
            <h5>
              <span><img src="../assets/images/car-icon.svg" alt="" /></span>
              Bedroom
            </h5>

            <ul>
              <li>
                <p>Lock on bedroom door</p>
              </li>
              <li>
                <p>Hangers</p>
              </li>
              <li>
                <p>Hair dryer</p>
              </li>
              <li>
                <p>Iron</p>
              </li>
              <li>
                <p>Washer</p>
              </li>
              <li>
                <p>Drye</p>
              </li>
            </ul>
            <h5>
              <span><img src="../assets/images/wifi.svg" alt="" /></span>
              Internet
            </h5>

            <ul>
              <li>
                <p>Lock on bedroom door</p>
              </li>
              <li>
                <p>Hangers</p>
              </li>
              <li>
                <p>Hair dryer</p>
              </li>
              <li>
                <p>Iron</p>
              </li>
              <li>
                <p>Washer</p>
              </li>
              <li>
                <p>Drye</p>
              </li>
            </ul>
          </div>
          <div class="col-md-3 col-lg-3">
            <h5>
              <span><img src="../assets/images/balcony.svg" alt="" /></span>
              Outdoors
            </h5>

            <ul>
              <li>
                <p>Lock on bedroom door</p>
              </li>
              <li>
                <p>Hangers</p>
              </li>
              <li>
                <p>Hair dryer</p>
              </li>
              <li>
                <p>Iron</p>
              </li>
              <li>
                <p>Washer</p>
              </li>
              <li>
                <p>Drye</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <section class="guest-review">
      <div class="container-fluid plr-80">
        <div class="row">
          <div class="col-12">
            <h2 class="heading">Guest reviews</h2>
          </div>

          <div class="col-12 read-review border-bottom">
            <div class="review d-flex">
              <span class="rating">8.6</span>
              <h5 class="text-center">
                Very good <span class="d-block text-center">2,176 reviews</span>
              </h5>
            </div>
            <a href="#" class="orange-button">Read all reviews</a>
          </div>
        </div>

        <div class="row rating-bar-wrapper border-bottom">
          <div class="col-md-5 col-lg-5">
            <div class="rating-bar-inner">
              <h4>Cleanliness</h4>
              <div class="rating-bar">
                <b-progress :value="value"></b-progress><span>8.0</span>
              </div>
            </div>
            <div class="rating-bar-inner">
              <h4>Communication</h4>
              <div class="rating-bar">
                <b-progress :value="value"></b-progress><span>8.0</span>
              </div>
            </div>
            <div class="rating-bar-inner">
              <h4>Check-in</h4>
              <div class="rating-bar">
                <b-progress :value="value"></b-progress><span>8.0</span>
              </div>
            </div>
          </div>
          <div class="col-md-1"></div>
          <div class="col-md-5 col-lg-5">
            <div class="rating-bar-inner">
              <h4>Accuracy</h4>
              <div class="rating-bar">
                <b-progress :value="value"></b-progress><span>8.0</span>
              </div>
            </div>
            <div class="rating-bar-inner">
              <h4>Location</h4>
              <div class="rating-bar">
                <b-progress :value="value"></b-progress><span>8.0</span>
              </div>
            </div>
            <div class="rating-bar-inner">
              <h4>Value</h4>
              <div class="rating-bar">
                <b-progress :value="value"></b-progress><span>8.0</span>
              </div>
            </div>
          </div>
        </div>

        <div class="row guest-rating">
          <div class="col-md-5 col-lg-5">
            <div class="guest-rating-inner">
              <div class="avtaar-cnt d-flex">
                <b-avatar src="../assets/images/user-image.jpg"></b-avatar>
                <h5>John Doe<span>July 2021</span></h5>
              </div>
              <p>
                “ Very nice place, very kind hospitality, every morning
                different homemade breakfast, fresh fruit salad, so it was
                fantastic ”
              </p>
            </div>
            <div class="guest-rating-inner">
              <div class="avtaar-cnt d-flex">
                <b-avatar src="../assets/images/user-image.jpg"></b-avatar>
                <h5>John Doe<span>July 2021</span></h5>
              </div>
              <p>
                “ Very nice place, very kind hospitality, every morning
                different homemade breakfast, fresh fruit salad, so it was
                fantastic ”
              </p>
            </div>
            <div class="guest-rating-inner">
              <div class="avtaar-cnt d-flex">
                <b-avatar src="../assets/images/user-image.jpg"></b-avatar>
                <h5>John Doe<span>July 2021</span></h5>
              </div>
              <p>
                “ Very nice place, very kind hospitality, every morning
                different homemade breakfast, fresh fruit salad, so it was
                fantastic ”
              </p>
            </div>
          </div>
          <div class="col-md-1 col-lg-1"></div>
          <div class="col-md-5 col-lg-5">
            <div class="guest-rating-inner">
              <div class="avtaar-cnt d-flex">
                <b-avatar src="../assets/images/user-image.jpg"></b-avatar>
                <h5>John Doe<span>July 2021</span></h5>
              </div>
              <p>
                “ Very nice place, very kind hospitality, every morning
                different homemade breakfast, fresh fruit salad, so it was
                fantastic ”
              </p>
            </div>
            <div class="guest-rating-inner">
              <div class="avtaar-cnt d-flex">
                <b-avatar src="../assets/images/user-image.jpg"></b-avatar>
                <h5>John Doe<span>July 2021</span></h5>
              </div>
              <p>
                “ Very nice place, very kind hospitality, every morning
                different homemade breakfast, fresh fruit salad, so it was
                fantastic ”
              </p>
            </div>
            <div class="guest-rating-inner">
              <div class="avtaar-cnt d-flex">
                <b-avatar src="../assets/images/user-image.jpg"></b-avatar>
                <h5>John Doe<span>July 2021</span></h5>
              </div>
              <p>
                “ Very nice place, very kind hospitality, every morning
                different homemade breakfast, fresh fruit salad, so it was
                fantastic ”
              </p>
            </div>
          </div>
        </div>

        <div class="contactUs row">
          <div class="col-12">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d310846.42026680266!2d13.144546492397764!3d52.50651325368295!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a84e373f035901%3A0x42120465b5e3b70!2sBerlin%2C%20Germany!5e0!3m2!1sen!2sin!4v1634733672625!5m2!1sen!2sin"
              width="100%"
              height="538"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
            ></iframe>
          </div>
          <div class="contactUs-txt d-flex">
            <div class="col-md-5 col-lg-5">
              <h2 class="heading">Berlin, Germany</h2>
              <p class="">
                The house is located in Moabit, part of the famous Berlin-Mitte
                district. As the meeting point between the historic east and
                west, there's a multitude of cultural, nightlife and
                sightseeing...
              </p>
              <a href="#" class="anchor-txt">More about the location</a>
            </div>
            <div class="col-1"></div>
            <div class="col-md-5 col-lg-5">
              <h2 class="heading">Distance from Berlin Tegel Airport</h2>
              <p>15 mins by car without traffic</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer ref="footerRef"></Footer>
  </div>
</template>

<script>
import HotelDatePicker from "vue-hotel-datepicker";
import "vue-hotel-datepicker/dist/vueHotelDatepicker.css";
export default {
  name: "Property",
  components: {
    HotelDatePicker,
  },

  data() {
    return {
      showBookNow: true,
      selected: "null",
      options: [
        {
          text: "0",
          value: null,
        },
        {
          text: "1",
          value: 1,
        },
        {
          text: "2 ",
          value: 2,
        },
        {
          text: "3 ",
          value: 3,
        },
        {
          text: "4 ",
          value: 4,
        },
      ],
      value: "",
    };
  },
  methods: {
    onScroll() {
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      var element = this.$refs.footerRef;
      var top = element.offsetTop;

      if (currentScrollPosition >= top) {
        this.showBookNow = false;
      } else {
        this.showBookNow = true;
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
};
</script>

